@import "../../import.scss";

.Home {
  .MuseumName {
    font-size: 200%;
    margin: 10px;
    font-family: serif;
    margin-bottom: 0;
  }

  .lockedText {
    text-align: center;
    padding-bottom: 12px;
    font-size: 90%;
  }
  .sub {
    text-align: center;
  }
  .welcomeImage {
    height: 250px;
    margin: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .description {
    padding: 10px;
    background-color: $white;
    border-radius: 4px;
  }
  .buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0 -12px 6px 0px $white;
    padding-left: 10px;
    padding-right: 10px;
  }
}
