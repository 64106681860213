@import "../../import.scss";

.Layout {
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: #222;
  flex: 1;
  > .inner {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
