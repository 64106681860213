@import "../../import.scss";

.singleExponat {
  display: flex;
  flex-direction: column;

  .exponatImages {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    height: 250px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--accent-color);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    .expoImage {
      width: 100%;
      min-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      box-sizing: border-box;

      scroll-snap-align: start;
      scroll-snap-stop: always;
      overflow: hidden;
      border-radius: 6px;
      > img {
        border-radius: 6px;

        object-fit: cover;
        border-radius: 6px;
        flex: 1;
        width: 100%;
      }
    }
  }
  .anchors {
    display: flex;
    align-items: center;
    justify-content: center;
    .carouselAnchor {
      text-decoration: none;

      line-height: 10px;
      width: 20px;
      text-align: center;
      &:focus {
        color: $blue;
      }
    }
  }
  .box {
    padding: 10px;
    border-radius: 6px;
    background-color: $white;
  }
  .subTitle {
    font-size: 130%;
    font-family: serif;
    margin-bottom: 12px;
  }
  .exponatName {
    font-size: 150%;
    font-weight: bold;
    font-family: serif;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .exponatInfo {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 24px;

    .exponatDescription {
      white-space: pre-wrap;
    }
    .exponatData {
      .exponatDataItem {
        display: flex;
        margin-bottom: 8px;
        u .exponatDataItemKey {
          flex: 1;
          font-weight: bold;
        }
        .exponatDataItemValue {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    border-radius: 6px;
    overflow: hidden;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
