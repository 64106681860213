@import "../../import.scss";

.ShortLink {
  .scanError {
    text-align: center;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
  }
}
