@import "../../import.scss";

.TourList {
  padding-left: 10px;
  padding-right: 10px;
  .title {
    color: $black;
    font-family: serif;
    font-size: 140%;
    font-weight: bold;
    padding-left: 25px;
    margin-top: 25px;
  }

  .tourListList {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }
  .tourLink {
    margin-bottom: 0;

    background-color: $white;
    display: block;
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    border-radius: 6px;
    overflow: hidden;

    .tourHero {
      display: flex;
      flex-direction: column;
      height: 220px;
      overflow: hidden;
      position: relative;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .tourName {
      text-align: center;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .infos {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      margin-top: 12px;
      .infoField {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: bold;
        .icon {
          font-size: 140%;
        }
      }
    }
  }
}
