@import "../../import.scss";

.ExponateModeSelect {
  padding-left: 10px;
  padding-right: 10px;
  .expoModeTitle {
    font-size: 120%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    flex-direction: column;
  }
  .expoModeButtons {
    display: flex;
    flex: 2;
    flex-direction: column;
    text-align: center;
    .text {
      font-size: 90%;
      padding: 24px;
      border-radius: 6px;
    }
  }
}
