@import "../../import.scss";

.UnlockByUrl {
  padding: 12px;
  text-align: center;
  .error {
    text-align: center;
    color: $red;
    font-size: 90%;
  }
}
