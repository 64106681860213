@import "../../import.scss";

.SwipeGallery {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: 100%;
  height: 100%;
  > img {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    width: 100%;
    min-width: 100%;
    object-fit: contain;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--accent-color);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.closeGalleryButton {
  position: fixed;
  bottom: 8px;
  right: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color);
  border-radius: 50%;
  color: $white;
}
