@import "../../import.scss";

.Nav {
  a {
    text-decoration: none;
    color: inherit;
  }
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: $white;
  display: flex;

  box-shadow: 0px 0px 6px 0px $white;
  .iconHolder {
    font-size: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }
  .backButton {
  }
  .headline {
    flex: 1;
    text-align: center;
  }
  .languageButton {
  }
  .menuButton {
  }
  .mainLogo {
    height: 40px;
  }
}

.menuItem {
  text-align: center;

  padding: 8px;
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  gap: 48px;
  cursor: pointer;
  border-bottom: 1px solid $black;
  height: 50px;
  &.noBorder {
    border: none;
  }
  .languageName {
    flex: 2;
    text-align: left;
  }
  &:first-child {
    border-top: 1px solid $black;
  }
}

.poweredBy {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 140%;
  margin-bottom: 10px;
  .bottom {
    margin-top: 8px;
    .orange {
      color: var(--accent-color);
    }
  }
}

.fontSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  .smallFont {
    font-size: 70%;
    width: 60px;
    text-align: center;
  }
  .mediumFont {
    font-size: 100%;
    width: 60px;
    text-align: center;
  }
  .largeFont {
    font-size: 130%;
    width: 60px;
    text-align: center;
  }
}
