@import "../../import.scss";

.TreasureHunt {
}
.treasureImageCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  .treasureImage {
    max-width: 360px;
    max-height: 250px;
    width: 100%;
    border-radius: 6px;
    object-fit: contain;
  }
}
.treasureText {
  padding: 10px;
  background-color: $white;
  border-radius: 6px;
  max-height: 40vh;
  overflow-y: auto;
  .title {
    font-weight: bold;
    font-size: 150%;
    margin-bottom: 12px;
    font-family: serif;
  }
}
.treasureModal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .treasureModalInner {
    width: 80%;
    background-color: white;
    border-radius: 6px;
    padding: 8px;
    text-align: center;
    max-width: 350px;
  }
}
.treasureTextContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 10px;
  .flex {
    display: flex;
    .questionmarkButton {
      width: 60px;

      background-color: #555;
    }
    > .greenButton {
      flex: 1;
    }
  }
}

.huntDone {
  border-radius: 6px;
  background-color: $green;
  font-weight: bold;
  color: white;
  padding: 10px;
}
