@import "../../import.scss";

.QrButton {
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 12px;
  right: 12px;
  border-radius: 50%;
  background-color: $white;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  font-size: 250%;
}
