@import "../../import.scss";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00000066;
  height: 100%;

  display: flex;
  z-index: 30;
  .inner {
    overflow: hidden;
    overflow-y: auto;
    background-color: $white;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    justify-content: center;
    .closeModalButton {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 6px;
      background-color: $white;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
