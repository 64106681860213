@import "../../import.scss";

.Unlock {
  padding: 12px;
  .centerOr {
    text-align: center;
  }
  .codeInput {
    height: 50px;
    border-radius: 6px;
    outline: none;
    border: none;
    border: 1px solid #999;
    text-align: center;
  }
  .error {
    font-size: 90%;
    color: $red;
    text-align: center;
  }
}
