$darkGrey: #28343d;
$lightGrey: #32414d;

$background: #ececec;

$green: #89bd21;
$blue: #2997ff;
$yellow: #cec600;
$red: #ee7157;

$darkGreen: #5d8720;
$darkBlue: #007078;
$darkYellow: #a99a00;
$darkRed: #da594c;

$darkerGreen: #486c17;
$darkerBlue: #005055;
$darkerYellow: #837800;
$darkerRed: #890c00;

$orange: #E33E09;

$darkText: #2a343c;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$white: #f2f2f2;
$black: #222222;

$lightShadow: 0px 0px 2px 0px #00000044;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.flex1 {
  flex: 1;
}

.shadow{
  box-shadow: 0px 3px 6px #00000042;
}