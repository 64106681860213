@import "../../import.scss";
.exponatListTitle {
  color: #222222;
  font-family: serif;
  font-size: 140%;
  font-weight: bold;
  padding-left: 35px;
  margin-top: 25px;
}
.ExponatList {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 8px;
  padding-bottom: 70px;

  .exponatItem {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #fff;
    border-radius: 6px;
    .exponatInfo {
      display: flex;
      align-items: center;
      .exponatIcons {
        color: $blue;
        font-size: 120%;
        display: flex;
        gap: 8px;
        padding-right: 8px;
      }
      .exponatName {
        flex: 1;
        text-align: center;
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
    .exponatImage {
      width: 100%;
      height: 200px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
