@import "../../import.scss";

.RoundIconButton {
  height: 56px;
  flex: 1;
  border-radius: 6px;
  background-color: var(--accent-color);
  color: $blue;
  font-size: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  text-decoration: none;
  &.disabled {
    background-color: #555;
    color: #999;
  }
  &.greenButton {
    background-color: $green;
  }
}
