@import "../../import.scss";

.TourComplete {
  padding-left: 10px;
  padding-right: 10px;
  .center {
    text-align: center;
    font-weight: bold;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 150%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    .icon {
      font-size: 400%;
      color: $green;
    }
  }
}
