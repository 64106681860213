@import "../../import.scss";

.TourMap {
}
.TourControls {
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 12px;
  margin-top: 8px;
  .disabled {
    background-color: #555;
  }
  .tourProgress {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: $white;
    .tourProgressDot {
      &.done {
        color: #ddd;
      }
      &.current {
        color: $blue;
      }
    }
  }
}
.TourExponatOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  .buttons {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    gap: 10px;

    pointer-events: all;
  }

  .expoTop {
    .grabOverlay {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      touch-action: none;
      z-index: 10;
      &.open {
        display: none;
      }
    }
    pointer-events: all;
    user-select: none;
    .grabBar {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      touch-action: none;
      position: sticky;
      left: 0;
      right: 0;
      top: 0;
      background-color: $white;
      border-radius: 6px;
      z-index: 5;
      .grabBarInner {
        height: 4px;
        width: 80%;
        border-radius: 2px;
        background-color: #ddd;
      }
    }
    background-color: $white;
    touch-action: none;
    overflow-y: hidden;
    margin: 10px;
    overscroll-behavior: none;

    margin-top: 0;
    margin-bottom: 0;

    border-radius: 6px;
    flex: 0;

    padding-top: 0;
    position: relative;
    min-height: 34vh;
  }
  .expoAudio {
    pointer-events: all;
    background-color: $white;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px;
    border-radius: 6px;
  }
  .closeOverlayButton {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    pointer-events: all;
  }
  .tourOverlayScrollArea {
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    background-clip: padding-box;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #777;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .exponatName {
    margin-top: 5px;
    font-family: serif;
    font-size: 140%;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .exponatDescription {
  }
  .imageTitle {
    font-size: 120%;
    font-family: serif;
  }
  .smallGallery {
    display: flex;
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -10px;
    margin-left: -10px;
    overflow-y: auto;

    .smallGalleryImage {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.tourExponatImageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: saturate(180%) blur(20px);
  background: rgba(34, 34, 34, 0.867);
  > img {
    max-width: 100%;
    max-height: 100%;
  }
  .closeExponatImageOverlay {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}

.fullLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  gap: 24px;
}
