@import "../../import.scss";
.scanWarpper {
  flex: 5;
  display: flex;
  position: relative;
  border-radius: 6px;
  .scanTitle {
    position: absolute;
    left: 0;
    right: 0;
    color: $white;
    font-size: 200%;
    z-index: 32;
    text-align: center;
    font-weight: bold;
    margin-top: 24px;
  }
  .viewFinder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 32;
    pointer-events: none;
    color: $white;
    font-size: 2900%;
  }
}
.Scanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;

  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #22222299;

  .scannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 32;
    pointer-events: none;
    .icon {
      font-size: 800%;
      margin-top: 24px;
      &.error {
        color: $red;
      }
      &.success {
        color: $green;
      }
    }
  }
}

.scannerButton {
  position: fixed;
  bottom: 12px;
  right: 81px;
  width: 45px;
  height: 45px;
  font-size: 150%;
  background-color: #27ae60;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  touch-action: none;
  border: 2px solid #219251;
  z-index: 2;
}

.KeyPad {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $white;
  padding: 24px;
  padding-left: 48px;
  padding-right: 48px;
  border-radius: 6px;
  padding-top: 0;
  position: relative;
  .keypadOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .keypadOverlayInner {
      background-color: $white;
      width: 300px;
      height: 300px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .icon {
        font-size: 500%;
        &.error {
          color: $red;
        }
        &.success {
          color: $green;
        }
      }
      .text {
        font-size: 200%;
        font-family: serif;
      }
    }
  }
  .row {
    display: flex;
    flex: 1;
    gap: 8px;
    &.resRow {
      max-height: 80px;
    }
    &.titleRow {
      font-size: min(10vw, 200%);
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .key {
      flex: 1;
      background-color: $white;
      color: $black;
      font-weight: bold;
      font-size: min(10vw, 200%);
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      padding: none;
      border: none;
      border-radius: 8px;
      &:active {
        opacity: 0.8;
      }
      &.iconKey {
        font-size: 10vw;
        overflow: hidden;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
    .value {
      flex: 1;
      border-radius: 8px;
      display: flex;
      font-size: 200%;
      color: $black;
      font-weight: bold;
      gap: 24px;
      align-items: center;
      justify-content: center;
      .valueField {
        max-width: 80px;
        height: 80px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
