@import "../../import.scss";

.videoDialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #444;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 80%;
  a {
    color: inherit;
  }
}
