@import "../../import.scss";

.Dual3DViewer {
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;

  .openFullScreenButton {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 40px;
    width: 40px;
    background-color: var(--accent-color);
    z-index: 8;
    border-radius: 50%;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.threeDeeoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 21;
  .closeButton {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 8;
    height: 40px;
    width: 40px;
    background-color: var(--accent-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
  }
}
