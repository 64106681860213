@import "../../import.scss";



.floatingPortion {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  .floatingAudio {
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    background-color: $white;
  }
}
