@import "../../import.scss";

.LanguageSelector {
  font-size: 120%;
  .museumName {
    font-weight: bold;
    font-family: serif;
    color: var(--accent-color);
    font-size: min(10vw, 140%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.languageList {
  .langOption {
    text-align: center;

    padding: 8px;

    display: flex;
    justify-content: center;
    transition: background-color 0.3s;
    text-transform: uppercase;
    align-items: center;
    gap: 48px;
    cursor: pointer;
    border-top: 1px solid $black;
    font-size: min(5vw, 200%);
    .languageName {
      flex: 2;
      text-align: left;
    }
    &:last-child {
      border-bottom: 1px solid $black;
    }

    .flag {
      height: 50px;
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      img {
        width: min(10vw, 50px);
        height: min(10vw, 50px);
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
