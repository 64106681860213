@import "../../import.scss";

.SingleTour {
  flex: 1;
  display: flex;
  flex-direction: column;
  .buttons {
    padding-left: 10px;
    padding-right: 10px;
  }
  .singleTourInfo {
    flex: 1;

    display: flex;
    flex-direction: column;
  }
  .tourImage {
    border-radius: 6px;
    height: 250px;
    margin-right: 10px;
    margin-left: 10px;
    object-fit: cover;
    width: calc(100% - 20px);
    overflow: hidden;
  }
  .pointGrid {
    overflow-y: auto;
    max-width: 100vw;
    .title {
      font-family: serif;
      font-size: 120%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .grid {
    display: flex;
    overflow-y: auto;
    padding: 10px;
    gap: 12px;

    .gridItem {
      min-width: 100px;
      max-width: 100px;
      height: 100px;
      overflow: hidden;
      background-color: $white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .singleTourInfo {
    .tourName {
      margin-bottom: 8px;
      font-size: 140%;
      font-weight: bold;
      font-family: serif;
      margin-top: 24px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .tourDesc {
      white-space: pre-wrap;
      padding-left: 10px;
      padding-right: 10px;
    }
    .tourInfo {
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 15px;
      padding-bottom: 15px;

      border-radius: 6px;
      display: flex;
      .tourInfoItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 140%;
        }
        font-weight: bold;
      }
    }
  }
}
