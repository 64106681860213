@import "../../import.scss";

.Button {
  height: 56px;
  background-color: var(--accent-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: $white;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  font-size: 120%;
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    text-align: center;
  }
  &.greenButton {
    background-color: $green;
  }
}
