@import "../../import.scss";

.MenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $white;
  z-index: 35;
  display: flex;
  flex-direction: column;
  .inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
}
