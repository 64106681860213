@import "../../import.scss";

.AudioPlayer {
  .rhap_container {
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    color: #222;
    .rhap_horizontal .rhap_controls-section {
      flex: 0;
    }
    .rhap_time {
      color: $black;
      font-size: 80%;
    }
    .rhap_progress-filled,
    .rhap_progress-indicator,
    .rhap_volume-indicator {
      background-color: var(--accent-color);
    }
    .rhap_play-pause-button {
      color: var(--accent-color);
    }
    .rhap_volume-button {
      color: #222;
    }
  }
}
